import Header from "./components/Header.js";
import { Outlet } from "react-router-dom";
function App() {
  return (
    <div className="font-sans">
      <Header />
      <Outlet />
      {/* <p>
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
        loremloremloremloremloremloremlorem loremloremlorem lorem lorem
        loremloremlorem loremloremloremloremloremloremlorem loremloremlorem
        lorem lorem loremloremlorem loremloremloremloremloremloremlorem
        loremloremlorem lorem lorem loremloremlorem
      </p> */}
    </div>
  );
}

export default App;
